import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

export const ContactUs = () => {

   // emailjs.sendForm('service_iah5fzb', 'template_dteurvi', form.current, 'qjrVa928un21z8IM2')
   const form = useRef();
   const [formData, setFormData] = useState({
     user_name: '',
     user_email: '',
     message: '',
   });
   const [notification, setNotification] = useState(null);
 
   const sendEmail = (e) => {
     e.preventDefault();
 
     emailjs.sendForm('service_iah5fzb', 'template_dteurvi', form.current, 'qjrVa928un21z8IM2')
       .then((result) => {
         console.log(result.text);
         // Clear the form and show notification on successful submission
         setFormData({ user_name: '', user_email: '', message: '' });
         setNotification('Message sent successfully!');
         setTimeout(() => setNotification(null), 3000); // Clear notification after 3 seconds
       })
       .catch((error) => {
         console.log(error.text);
         // Handle error if needed
       });
   };
 
   const handleInputChange = (e) => {
     const { name, value } = e.target;
     setFormData((prevData) => ({
       ...prevData,
       [name]: value,
     }));
   };

  return (
    <form ref={form} onSubmit={sendEmail} className="app__wrapper-form">
      <label htmlFor="name" className="label__opensans">Name</label>
      <input type="text" id="name" name="user_name" className="input__opensans" required  value={formData.user_name} onChange={handleInputChange} />
      <label htmlFor="email" className="label__opensans">Email</label>
      <input type="email" id="email" name="user_email" className="input__opensans" required value={formData.user_email} onChange={handleInputChange} />
      <label htmlFor="message"className="label__opensans">Message</label>
      <textarea id="message" name="message" className="textarea__opensans" required value={formData.message} onChange={handleInputChange} />
      <input type="submit" value="Send" className="custom__button2" style={{ marginTop: '2rem' }}/>
      {notification && <p style={{ marginTop: '2rem', fontFamily: 'var(--font-base)' }}>{notification}</p>}
    </form>
  );
};