import React from 'react';
import { FiInstagram } from 'react-icons/fi';

import { images } from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className="app__footer section__padding" id="login">

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">Email: SocialHourLABar@gmail.com</p>
        <p className="p__opensans">+1 562-485-1641</p>
      </div>

      <div className="app__footer-links_logo">
        <img src={images.socialhour} alt="footer_logo" />
        <p className="p__opensans">Personalized cocktails for any event. Elevate your party experience with us!</p>
        <div className="app__footer-links_icons">
        <a href="https://www.instagram.com/socialhourla" target="_blank" rel="noopener noreferrer" class="app__footer-links_icons">
          <FiInstagram />
          </a>
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Working Hours</h1>
        <p className="p__opensans">Contact us now for availability.</p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">2024 SocialHourLA. All Rights reserved.</p>
    </div>

  </div>
);

export default Footer;