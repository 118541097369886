import React from 'react';

import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => (
  <div className="app__aboutus app__bg flex__center section__padding" id="about">
    <div className="app__aboutus-overlay flex__center">
      </div>

      <div className="app__aboutus-content flex__center">
        <div className="app__aboutus-content_about">
          <h1 className="headtext__cormorant">About Us</h1>
          <p className="p__opensans">Welcome to Social Hour LA, your premier mobile bartending service dedicated to crafting personalized cocktails for any event!</p>
          <a href="#contact"><button type="button" className="custom__button">Know More</button></a>
        </div>  

          <div className="app__aboutus-content_glass flex__center">
            <img src={images.glass} alt="about_glass" />
          </div>

          <div className="app__aboutus-content_history">
          <h1 className="headtext__cormorant">Our History</h1>
          <p className="p__opensans">Our journey is just starting. Fueled by excellence, we're ready to work hard, create unforgettable moments, and grow with our clients. Join us in shaping the history of Social Hour LA, where every sip tells a story and every gathering is a celebration. Cheers to new beginnings!</p>
          <a href="#contact"><button type="button" className="custom__button">Know More</button></a>
        </div>  


        </div>
  </div>
);

export default AboutUs;
