import React from 'react';

import { SubHeading } from '../../components'
import { images } from '../../constants';
import './Header.css';

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
      <SubHeading title="Mix & Mingle"/>
      <h1 className="app__header-h1">Crafting Tailored Cocktails for Every Occasion.</h1>
      <p className="p__opensans" style={{ margin: '2rem 0'}}>Personalized cocktails for any event. Elevate your party experience with us!</p>
      <a href="#contact"><button type="button" className="custom__button">Contact Us Now</button></a>
      </div>
 
      <div className="app__wrapper_img">
        <img src={images.welcome} alt="header img"></img>
        </div>
  </div>
);

export default Header;
