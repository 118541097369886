import bg from "../assets/bg.png";
import gallery01 from "../assets/gallery01.gif";
import gallery02 from "../assets/gallery02.jpg";
import gallery03 from "../assets/gallery03.jpg";
import gallery04 from "../assets/gallery04.gif";
import glass from "../assets/glass.png";
import overlaybg from "../assets/overlaybg.png";
import spoon from "../assets/spoon.svg";
import welcome from "../assets/welcome.PNG";
import findus from "../assets/findus-modified.png";
import socialhour from "../assets/socialhourlogo.jpg";

export default {
  bg,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  glass,
  findus,
  overlaybg,
  spoon,
  welcome,
  socialhour,
};
