import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import { ContactUs } from './ContactUs';

const FindUs = () => (
  <div className="app__bg app__wrapper section__padding" id="contact">
    <div className="app__wrapper_info">
      <SubHeading title="Talk to us now" />
      <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}>Contact Us</h1>
      <div className="app__wrapper-content" style={{ marginBottom: '3rem' }}>
        
        <p className="p__opensans">Email: SocialHourLABar@gmail.com</p>
        <p className="p__opensans">Phone Number: (562) 485-1641</p>
      </div>
      <div className="app__wrapper-flex-container">
        <ContactUs />
      {/* <form className="app__wrapper-form">
          <label htmlFor="name" className="label__opensans">Your Name:</label>
          <input type="text" id="name" name="name" className="input__opensans" required />

          <label htmlFor="email" className="label__opensans">Your Email:</label>
          <input type="email" id="email" name="email" className="input__opensans" required />

          <label htmlFor="message" className="label__opensans">Your Message:</label>
          <textarea id="message" name="message" className="textarea__opensans" required />

          <button type="submit" className="custom__button2" style={{ marginTop: '2rem' }}>Submit Form</button>
</form> */}
      </div>
    </div>

    <div className="app__wrapper_img">
      <img src={images.findus} alt="finus_img" />
    </div>
  </div>
);

export default FindUs;